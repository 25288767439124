.pink-background {
    background-color: rgba(244, 204, 204, 0.6); /* 투명도 80% */
  }
  
  .blue-background {
    background-color: rgba(164, 194, 244, 0.6); /* 투명도 80% */
  }
  
  .yellow-background {
    background-color: rgba(254, 254, 155, 0.6); /* 투명도 80% */
  }
  
  .orange-background {
    background-color: rgba(253, 153, 4, 0.6); /* 투명도 80% */
  }
  
  .puple-background {
    background-color: rgba(245, 231, 254, 0.6); /* 투명도 80% */
  }
  
  .green-background {
    background-color: rgba(116, 203, 134, 0.6); /* 투명도 80% */
  }
  
  .white-background {
    background-color: rgba(255, 255, 255, 0.6); /* 투명도 80% */
  }
  