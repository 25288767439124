.blue-header {
  background-color: #5aa1e7 !important;
  text-align: center;
  color: white;
}
.blue-header1 {
  /*background-color: #55a0eb;*/
  background-color: #308153;
  font-size: 16px !important;
  height: 40px !important;
  padding-inline-start: 10px !important;
  color: white;
}
.blue-header2 {
  background-color: #9bbfab;
  text-align: center;
}
.left-header {
  line-height: normal;
  background-color: #9bbfab;
  text-align: left;
}

.blue-header3 {
  background-color: #f2f2f2;
}

.order-number {
  padding-top: 0;
  display: flex;
  /*justify-content: center;*/
  padding-block-start: 0 !important;
  background-color: #6cbaa2;
  font-size: 15px !important;
  padding-left: 5px;
}

.quantity-header {
  background-color: #d8f2e3;
  text-align: center;
}

.renderCell {
  padding-top: 0 !important;
}

.rowClass {
  padding-top: 0 !important;
  margin: 0 !important;
}

.AliasCell {
  color: #55a0eb !important;
}

.css-14lo706 {
  font-size: 0.9em !important;
}

.box-number-list {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.renderCell {
  padding: 10px;
  line-height: 1.5;
}

.padding0-cell {
  padding-inline: 0 !important;
}

.flex-cell {
  /* padding-inline: 0 !important; */
  background-color: #eef5fc;
}

.pre-cell {
  background-color: #dbfcfe;
}
/* .rdg-cell {
  padding-inline: 0 !important;
} */

.pink-cell {
  background-color: #ffb7c5 !important;
}

.text-header-cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  box-sizing: border-box;
  line-height: normal;
  padding: 0px;
  margin: 0px;
}
