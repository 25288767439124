@font-face {
  font-family: "malgun";
  src: url("./assets/font/malgun.ttf");
}
@font-face {
  font-family: "malgunbd";
  src: url("./assets/font/malgunbd.ttf");
}
@font-face {
  font-family: "malgunsl";
  src: url("./assets/font/malgunsl.ttf");
}

body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-family: "malgun", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

textarea,
button,
div,
p,
span,
pre {
  font-size: 12px;
  font-family: "malgun", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.fit-grid {
  block-size: 100%;
  background-color: #eeeeee;
}



.memo-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
}

.multi-line-header {
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: "center";
  /* inline-size: 100%; */
}

.yellow-header {
  background-color: #ffff98;
  text-align: center;
  color: #333;
}

.pink-header {
  background-color: #fadddf;
  text-align: center;
  color: #333;
}

.green-header {
  background-color: #f7fbf7;
  text-align: center;
}

.cell-divider {
  border-right: 1px solid #dddddd;
}

.common-cell {
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.skyblue-header {
  background-color: #eef5fc;
  text-align: center;
}

.disabled-cell {
  background-color: #f0f0f0;
  color: #666;
  /* text-align: center; */
}

.center-header {
  text-align: center;
}

.textarea-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin: 0px;
  height: 100%;
  width: 100%;
  resize: none;
}


.alert-cell {
  background-color: #ffaaaa;
}

.empty-margin-cell{
  background-color: #fff5cf;
}

.row-margin-price{
  background-color: #dcf1ff;
}
