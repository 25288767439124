.header {
  text-align: center;
}

.green-header {
  background-color: #daead2;
  text-align: center;
  color: #333;
}

.orange-header {
  background-color: #fde5cd;
  text-align: center;
  color: #333;
}

.purple-header {
  background-color: #f4ebfa;
  text-align: center;
  color: #333;
}

.yellow-header {
  background-color: #ffff98;
  text-align: center;
  color: #333;
}

.blue-header11 {
  background-color: #c9daf8;
  text-align: center;
  color: #333;
}

.pink-header {
  background-color: #fadddf;
  text-align: center;
  color: #333;
}

.rootClassname {
  display: flex;
  flex-direction: column;
  block-size: 100%;
  gap: 10px;
}

.toolbarClassname {
  text-align: end;
}

.rootClassname > .rdg {
  flex: 1;
}

.toolbarClassname {
  text-align: end;
}

.filterClassname {
  inline-size: 100%;
  padding: 4px;
  font-size: 14px;

  
}

.filter-cell {
  text-align: center;
  line-height: 40px;
  padding: 0;
}

.filter-cell > div {
  padding-block: 0;
  padding-inline: 8px;
}

.filter-cell > div:first-child {
  border-block-end: 1px solid var(--rdg-border-color);
}
