@keyframes fadeInMoveUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fadeInMoveUp {
  animation: fadeInMoveUp 0.5s ease forwards;
}

/* 즉시 실행되는 애니메이션 */
.fadeInMoveUp--delay-0 {
  animation-delay: 0s; /* 즉시 실행 */
}

/* 0.1초 후 실행되는 애니메이션 */
.fadeInMoveUp--delay-1 {
  animation-delay: 0.1s;
}

/* 0.3초 후 실행되는 애니메이션 */
.fadeInMoveUp--delay-2 {
    animation-delay: 0.2s;
  }

/* 0.3초 후 실행되는 애니메이션 */
.fadeInMoveUp--delay-3 {
  animation-delay: 0.3s;
}

/* 0.3초 후 실행되는 애니메이션 */
.fadeInMoveUp--delay-4 {
    animation-delay: 0.4s;
  }

/* 0.5초 후 실행되는 애니메이션 */
.fadeInMoveUp--delay-5 {
  animation-delay: 0.5s;
}
